<template>
  <div class="page flex-col">
    <div class="container-body flex-col">
      <!-- 中间部分标语 -->
      <div class="container">
      <div class="row block_3">
        <div class="col-sm-4">
          <p>
            <img class="image_2" referrerpolicy="no-referrer" src="../../assets/img/left-decorative-horizontal-bar.png"/>
          </p>
        </div>
        <div class="col-sm-4">
          <p class="text_6">让你轻松设计网络通信方案</p>
        </div>
        <div class="col-sm-4">
          <p>
            <img class="image_2" referrerpolicy="no-referrer" src="../../assets/img/right-decorative-horizontal-bar.png"/>
          </p>
        </div>
      </div>
      </div>

      <!-- 招聘信息板块 -->
      <div class="block_1 flex-col">
        <div class="container">
        <div class="block_4 flex-col">
          <div class="text-wrapper_2 flex-col"><span class="text_7">招贤纳士</span></div>
          <!-- 表单 -->
          <table v-for="(item, index) in recruitData0" :key="index" class="table" style="table-layout: fixed;">
            <tr>
              <td class="td-div-1" style="width:40%">
                <div class="td-div-2">
                  <img class="img-6" referrerpolicy="no-referrer" src="../../assets/img/per-icon.png" />
                  <span class="text-group_6">{{ item.positionName }}</span>
                </div>
              </td>
              <td style="width:60%"  rowspan ="2">
                <div class="text-wrapper_1 flex-col">
                <h5 class="text_25">岗位要求：</h5>
                <p class="paragraph_7" v-html="item.jobRequirements">
                </p>
                <h3 class="text_26">任职资格：</h3>
                <p class="paragraph_7" v-html="item.qualification">
                </p>
              </div>
              </td>
            </tr>
            <tr>
              <td class="td-div-1" style="width:40%" >
                <div class="td-div-2">
                  <img referrerpolicy="no-referrer" src="../../assets/img/education-icon.png" />
                  <span class="text-group_6"> {{ item.educational }}</span>
                </div>
              </td>
            </tr>
          </table>
        </div>
        </div>
      </div>

      <!-- 联系我们板块 -->
      <div class="block_1 flex-col">
        <div class="box_6 flex-col">
        <div class="text-wrapper_10 flex-col"><span class="text_15">联系我们</span></div>
        <div class="image-wrapper_3 flex-row justify-between">
        </div>
        <div class="container damu-threeC">
          <div class="row">
            <div class="col-sm-6 col-md-6">
              <div class="thumbnail">
                <img class="image_3" src="../../assets/img/address-icon.png" alt="...">
                <div class="caption">
                  <h5 class="caption-text-1"> 注册地址</h5>
                  <p class="caption-text-2">中国（上海）自由贸易试验区临港新片区
                    <br />
                    环湖西二路888号C楼
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6">
              <div class="thumbnail">
                <img class="image_4" src="../../assets/img/mailbox-icon.png" alt="...">
                <div class="caption">
                  <h5 class="caption-text-1">服务邮箱</h5>
                  <p class="caption-text-2">tianyuzl2022&#64;163.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section_4 flex-row">
          <img class="image_5" referrerpolicy="no-referrer" src="../../assets/img/company-map.png" />
          <div class="block_11 flex-col"></div>
          <div class="text-wrapper_13 flex-col justify-between">
            <span class="text_17">与我们联系</span>
            <p class="paragraph_11">
              我们将提供最好的服务给你
              </p>
          </div>
        </div>
      </div>
    </div> 
    </div>
  </div>
</template>
<script>
export default {
  name: "RecruitInfo",
  data() {
    return {
      menuIndex: 4,
      recruitData0: [
        {
          positionName: '计算机视觉工程师',
          educational : '硕士及以上',
          jobRequirements: '1、参与图像处理、图像识别、目标检测、图像分割等计算机视觉算法研发工作</br>2、解决计算机视觉与深度学习算法在实际场景中的优化问题',
          qualification: '1、计算机、网络通信或相关专业，有计算机视觉或深度学习项目经验者优先<br>2、熟悉常见的图像特征提取方法；熟悉常用的卷积神经网络框架、训练方法和调参方法<br>3、有人体姿态识别、图像分割、人脸关键点识别等计算机视觉算法开发经验<br>'+
                        '4、精通C/C++，Python等编程语言，熟悉基本的Linux指令<br>5、熟悉TensorFlow机器学习框架、OpenCV图像处理库；能独立完成算法实验、开发和测试<br>6、有图像识别应用开发项目经验者优先'
        },
        {
          positionName: 'python开发工程师',
          educational : '硕士及以上',
          jobRequirements: '1、负责室分设计绘图模块的调度系统研发，参与底层框架和系统架构的建设<br>2、负责系统的服务化部署与维护工作',
          qualification: '1、熟悉数据结构与算法，包括常见数据结构（树、图、堆等）与常见设计模式<br>2、熟练Python语⾔以及Django开发框架，有良好的编程规范<br>3、能够对算法的执行的空间和时间效率进行分析并解决性能瓶颈，熟悉常用的 Python 性能调优工具<br>'+
                        '4、熟练Linux、Docker以及⾃动化部署流程<br>5、熟悉分布式系统和容器化调度，熟悉云原生系统搭建和开源生态，对 Kubernetes 和云原生开发有一定的经验。有一定的系统架构设计能力者优先<br>6、逻辑清晰，专注技术有追求有热情，良好的团队合作精神及学习能⼒'
        },
        {
          positionName: 'C/C++高级工程师',
          educational : '硕士及以上',
          jobRequirements: '1、负责云端BIM建模引擎开发，包括BIM数据管理、构件几何造型、三维显示等核心功能开发<br>2、根据项目需求，负责系统设计文档编写、编码、自测等开发任务',
          qualification: '1、重点院校硕士及以上学历<br>2、三年及以上建模平台和图形引擎开发经验，有CAD二次开发经验者优先<br>3、精通C/C++编程，擅长嵌入式高性能多线程编程<br>'+
                        '4、熟悉BIM/CAD软件设计，有ACIS、Parasolid、OpenCASCADE等几何造型引擎开发经验优先<br>5、具备云化分布式设计和开发经验，对KVM、Docker、K8S有深入了解者优先<br>6、具备网络通信相关领域测试/问题处理经验者优先'
        },
      ],
      constants: {},
    };
  },
  created() {
    localStorage.setItem("menuIndex", this.menuIndex);
  },
  methods: {

    getInfo() {
      window.location.href = "http://localhost:8080/#/InformationDetails";
		},

    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
    },
    handleCurrentChange(val) {
      this.pageInfo.currentPage = val;
    }


  }
};
</script>
<style scoped lang="css" src="../../assets/recruitInfo.css" />